(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/simple-article/views/simple-article.js') >= 0) return;  svs.modules.push('/components/content/simple-article/views/simple-article.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.content=_svs.content||{};
_svs.content.simple_article=_svs.content.simple_article||{};
_svs.content.simple_article.templates=_svs.content.simple_article.templates||{};
svs.content.simple_article.templates.simple_article = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " padding-sm-0 "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasUrl") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":66},"end":{"line":1,"column":117}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "simple-article-overlay";
},"4":function(container,depth0,helpers,partials,data) {
    return " simple-article-overlay-right-side";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"error") || (depth0 != null ? lookupProperty(depth0,"error") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"error","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":17}}}) : helper)))
    + "\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closeBtn") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "\n        <meta itemprop=\"name\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":48}}}) : helper)))
    + "\">\n        <meta itemprop=\"author\" content=\"Svenska Spel\">\n        <meta itemprop=\"publisher\" content=\"Svenska Spel\">\n        <meta itemprop=\"datePublished\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"published") || (depth0 != null ? lookupProperty(depth0,"published") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"published","hash":{},"data":data,"loc":{"start":{"line":15,"column":48},"end":{"line":15,"column":61}}}) : helper)))
    + "\">\n        <meta itemprop=\"datePublished\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"published") || (depth0 != null ? lookupProperty(depth0,"published") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"published","hash":{},"data":data,"loc":{"start":{"line":16,"column":48},"end":{"line":16,"column":61}}}) : helper)))
    + "\">\n        <meta itemprop=\"dateCreated\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"published") || (depth0 != null ? lookupProperty(depth0,"published") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"published","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":59}}}) : helper)))
    + "\">\n        <meta itemprop=\"dateModified\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"modified") || (depth0 != null ? lookupProperty(depth0,"modified") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modified","hash":{},"data":data,"loc":{"start":{"line":18,"column":47},"end":{"line":18,"column":59}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":23,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"videos") : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":39,"column":17}}})) != null ? stack1 : "")
    + "        <section\n            class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closeBtn") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":19},"end":{"line":41,"column":66}}})) != null ? stack1 : "")
    + " simple-article-content padding-xs-2 padding-md-3 pop-up-box\"\n            itemprop=\"articleBody\">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"articleTitle") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.program(29, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":47,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"hasText")||(depth0 && lookupProperty(depth0,"hasText"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ingress") : depth0),{"name":"hasText","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":51,"column":24}}})) != null ? stack1 : "")
    + "            "
    + ((stack1 = (lookupProperty(helpers,"mediaInjector")||(depth0 && lookupProperty(depth0,"mediaInjector"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"body") : depth0),(depth0 != null ? lookupProperty(depth0,"images") : depth0),(depth0 != null ? lookupProperty(depth0,"videos") : depth0),{"name":"mediaInjector","hash":{},"data":data,"loc":{"start":{"line":52,"column":12},"end":{"line":52,"column":50}}})) != null ? stack1 : "")
    + "\n        </section>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"c2a") : depth0)) != null ? lookupProperty(stack1,"0") : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":89,"column":15}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-simple_article-simple_article_close"),depth0,{"name":"components-content-simple_article-simple_article_close","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"typeName") : depth0),"===","100",{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":22,"column":20}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),depth0,{"name":"components-content-image_v2-image","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),"===","100",{"name":"compare","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":38,"column":20}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"simple-article-video responsive-image-wrapper responsive-image-wrapper-fit\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"===","redbee",{"name":"compare","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":30,"column":24}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"===","youtube",{"name":"compare","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":33,"column":24}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"===","solidtango",{"name":"compare","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":36,"column":24}}})) != null ? stack1 : "")
    + "        </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-redbeemedia-video_player"),depth0,{"name":"components-components-redbeemedia-video_player","hash":{"fitWrapper":true},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-youtube_video-youtube_video"),depth0,{"name":"components-components-youtube_video-youtube_video","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-video_player-video_player"),depth0,{"name":"components-components-video_player-video_player","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    return "simple-article-padding-h";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"useH2ForTitle") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":43,"column":32},"end":{"line":44,"column":66}}})) != null ? stack1 : "")
    + "\n            ";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"h1\" itemprop=\"headline\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"articleTitle") || (depth0 != null ? lookupProperty(depth0,"articleTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"articleTitle","hash":{},"data":data,"loc":{"start":{"line":43,"column":88},"end":{"line":43,"column":106}}}) : helper))) != null ? stack1 : "")
    + "</h2>";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            <h1 itemprop=\"headline\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"articleTitle") || (depth0 != null ? lookupProperty(depth0,"articleTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"articleTitle","hash":{},"data":data,"loc":{"start":{"line":44,"column":36},"end":{"line":44,"column":54}}}) : helper))) != null ? stack1 : "")
    + "</h1>";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":47,"column":12}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"useH2ForTitle") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":45,"column":29},"end":{"line":46,"column":59}}})) != null ? stack1 : "")
    + "\n            ";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"h1\" itemprop=\"headline\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":45,"column":85},"end":{"line":45,"column":96}}}) : helper))) != null ? stack1 : "")
    + "</h2>";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1\n                itemprop=\"headline\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":46,"column":36},"end":{"line":46,"column":47}}}) : helper))) != null ? stack1 : "")
    + "</h1>";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = (lookupProperty(helpers,"appsInjector")||(depth0 && lookupProperty(depth0,"appsInjector"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ingress") : depth0),{"name":"appsInjector","hash":{},"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":49,"column":38}}})) != null ? stack1 : "")
    + "\n            <div class=\"f-summary \" itemprop=\"description\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"ingress") || (depth0 != null ? lookupProperty(depth0,"ingress") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"ingress","hash":{},"data":data,"loc":{"start":{"line":50,"column":59},"end":{"line":50,"column":72}}}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"37":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"simple-article-c2a\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"c2a") : depth0),{"name":"each","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":87,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"38":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"linkType") : depth0),"===","external",{"name":"compare","hash":{},"fn":container.program(39, data, 0, blockParams, depths),"inverse":container.program(42, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":86,"column":24}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"rel":"nofollow noopener noreferrer","data-impression":"true","data-track":(depth0 != null ? lookupProperty(depth0,"trackingId") : depth0),"data-area-name":(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"areaName") : depths[1]),"-",(lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]),"+",1,{"name":"math","hash":{},"data":data,"loc":{"start":{"line":65,"column":51},"end":{"line":65,"column":72}}}),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":65,"column":27},"end":{"line":65,"column":73}}}),"target":(depth0 != null ? lookupProperty(depth0,"linkTarget") : depth0),"size":300,"transparent":true,"inverted":(lookupProperty(helpers,"boolean")||(depth0 && lookupProperty(depth0,"boolean"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isInverted") : depths[1]),{"name":"boolean","hash":{},"data":data,"loc":{"start":{"line":61,"column":21},"end":{"line":61,"column":44}}}),"href":(depth0 != null ? lookupProperty(depth0,"linkUrl") : depth0),"class":"js-article "},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":12},"end":{"line":71,"column":19}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"linkDescription") || (depth0 != null ? lookupProperty(depth0,"linkDescription") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"linkDescription","hash":{},"data":data,"loc":{"start":{"line":70,"column":12},"end":{"line":70,"column":33}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"42":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"data-impression":"true","data-track":(depth0 != null ? lookupProperty(depth0,"trackingId") : depth0),"data-area-name":(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"areaName") : depths[1]),"-",(lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]),"+",1,{"name":"math","hash":{},"data":data,"loc":{"start":{"line":80,"column":51},"end":{"line":80,"column":72}}}),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":80,"column":27},"end":{"line":80,"column":73}}}),"transparent":true,"inverted":(lookupProperty(helpers,"boolean")||(depth0 && lookupProperty(depth0,"boolean"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isInverted") : depths[1]),{"name":"boolean","hash":{},"data":data,"loc":{"start":{"line":78,"column":21},"end":{"line":78,"column":44}}}),"size":300,"target":(depth0 != null ? lookupProperty(depth0,"linkTarget") : depth0),"href":(depth0 != null ? lookupProperty(depth0,"linkUrl") : depth0),"class":"js-article"},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":85,"column":19}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"f-content simple-article"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closeBtn") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":124}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRightSide") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":124},"end":{"line":1,"column":184}}})) != null ? stack1 : "")
    + "\"\n    itemscope itemtype=\"http://schema.org/Article\" data-cmsid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":63},"end":{"line":2,"column":69}}}) : helper)))
    + "\" data-cmsType=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":2,"column":85},"end":{"line":2,"column":100}}}) : helper)))
    + "\">\n    <div class=\"simple-article-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"error") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":90,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-content-simple_article-simple_article'] = svs.content.simple_article.templates.simple_article;
})(svs, Handlebars);


 })(window);