(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/simple-article/views/simple-article-summarized.js') >= 0) return;  svs.modules.push('/components/content/simple-article/views/simple-article-summarized.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.content=_svs.content||{};
_svs.content.simple_article=_svs.content.simple_article||{};
_svs.content.simple_article.templates=_svs.content.simple_article.templates||{};
svs.content.simple_article.templates.simple_article_summarized = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"error") || (depth0 != null ? lookupProperty(depth0,"error") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"error","hash":{},"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":4,"column":11}}}) : helper)))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <meta itemprop=\"name\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":42}}}) : helper)))
    + "\">\n  <meta itemprop=\"author\" content=\"Svenska Spel\">\n  <meta itemprop=\"publisher\" content=\"Svenska Spel\">\n  <meta itemprop=\"datePublished\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"published") || (depth0 != null ? lookupProperty(depth0,"published") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"published","hash":{},"data":data,"loc":{"start":{"line":10,"column":42},"end":{"line":10,"column":55}}}) : helper)))
    + "\">\n  <meta itemprop=\"dateCreated\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"published") || (depth0 != null ? lookupProperty(depth0,"published") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"published","hash":{},"data":data,"loc":{"start":{"line":11,"column":40},"end":{"line":11,"column":53}}}) : helper)))
    + "\">\n  <meta itemprop=\"dateModified\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"modified") || (depth0 != null ? lookupProperty(depth0,"modified") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modified","hash":{},"data":data,"loc":{"start":{"line":12,"column":41},"end":{"line":12,"column":53}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"videos") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + "  <section class=\"simple-article-content padding-xs-2 padding-md-3\" itemprop=\"articleBody\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":36,"column":56}}})) != null ? stack1 : "")
    + "\n    <div class=\"f-summary \" itemprop=\"description\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"summary") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":38,"column":6},"end":{"line":50,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </section>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"typeName") : depth0),"===","100",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":16,"column":14}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),depth0,{"name":"components-content-image_v2-image","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),"===","100",{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":2},"end":{"line":32,"column":14}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"simple-article-video responsive-image-wrapper responsive-image-wrapper-fit\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"===","redbee",{"name":"compare","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":24,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"===","youtube",{"name":"compare","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":27,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"===","solidtango",{"name":"compare","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":30,"column":16}}})) != null ? stack1 : "")
    + "  </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-redbeemedia-video_player"),depth0,{"name":"components-components-redbeemedia-video_player","hash":{"fitWrapper":true},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-youtube_video-youtube_video"),depth0,{"name":"components-components-youtube_video-youtube_video","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-video_player-video_player"),depth0,{"name":"components-components-video_player-video_player","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"useH2ForTitle") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":35,"column":17},"end":{"line":36,"column":49}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"h1\" itemprop=\"headline\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":35,"column":73},"end":{"line":35,"column":84}}}) : helper))) != null ? stack1 : "")
    + "</h2>";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1\n      itemprop=\"headline\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":36,"column":26},"end":{"line":36,"column":37}}}) : helper))) != null ? stack1 : "")
    + "</h1>";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span class=\"simple-article-inline-link\">"
    + ((stack1 = (lookupProperty(helpers,"addEllipsis")||(depth0 && lookupProperty(depth0,"addEllipsis"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"summary") : depth0),{"name":"addEllipsis","hash":{},"data":data,"loc":{"start":{"line":39,"column":47},"end":{"line":39,"column":72}}})) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-simple_article-simple_article_read_more"),depth0,{"name":"components-content-simple_article-simple_article_read_more","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"hasText")||(depth0 && lookupProperty(depth0,"hasText"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ingress") : depth0),{"name":"hasText","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":49,"column":18}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = (lookupProperty(helpers,"appsInjector")||(depth0 && lookupProperty(depth0,"appsInjector"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ingress") : depth0),{"name":"appsInjector","hash":{},"data":data,"loc":{"start":{"line":43,"column":6},"end":{"line":43,"column":32}}})) != null ? stack1 : "")
    + "\n      <span class=\"simple-article-inline-link\">"
    + ((stack1 = (lookupProperty(helpers,"showSummarized")||(depth0 && lookupProperty(depth0,"showSummarized"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ingress") : depth0),{"name":"showSummarized","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":47},"end":{"line":44,"column":93}}})) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-simple_article-simple_article_read_more"),depth0,{"name":"components-content-simple_article-simple_article_read_more","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    return "";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span class=\"simple-article-inline-link\">"
    + ((stack1 = (lookupProperty(helpers,"showSummarized")||(depth0 && lookupProperty(depth0,"showSummarized"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"body") : depth0),{"name":"showSummarized","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":47},"end":{"line":47,"column":90}}})) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-simple_article-simple_article_read_more"),depth0,{"name":"components-content-simple_article-simple_article_read_more","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"simple-article-c2a\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"c2a") : depth0),{"name":"each","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":2},"end":{"line":89,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"29":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  "
    + container.escapeExpression(lookupProperty(helpers,"log").call(alias1,depths[1],{"name":"log","hash":{},"data":data,"loc":{"start":{"line":58,"column":2},"end":{"line":58,"column":16}}}))
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"linkType") : depth0),"===","external",{"name":"compare","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.program(33, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":59,"column":2},"end":{"line":88,"column":14}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"rel":"nofollow noopener noreferrer","data-impression":"true","data-track":(depth0 != null ? lookupProperty(depth0,"trackingId") : depth0),"data-area-name":(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"areaName") : depths[1]),"-",(lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]),"+",1,{"name":"math","hash":{},"data":data,"loc":{"start":{"line":67,"column":41},"end":{"line":67,"column":62}}}),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":67,"column":17},"end":{"line":67,"column":63}}}),"target":(depth0 != null ? lookupProperty(depth0,"linkTarget") : depth0),"size":300,"transparent":true,"inverted":false,"href":(depth0 != null ? lookupProperty(depth0,"linkUrl") : depth0),"class":"js-article"},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":2},"end":{"line":73,"column":9}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"linkDescription") || (depth0 != null ? lookupProperty(depth0,"linkDescription") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"linkDescription","hash":{},"data":data,"loc":{"start":{"line":72,"column":2},"end":{"line":72,"column":23}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"33":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"data-impression":"true","data-track":(depth0 != null ? lookupProperty(depth0,"trackingId") : depth0),"data-area-name":(depths[1] != null ? lookupProperty(depths[1],"areaName") : depths[1]),"target":(depth0 != null ? lookupProperty(depth0,"linkTarget") : depth0),"size":300,"transparent":true,"inverted":(lookupProperty(helpers,"boolean")||(depth0 && lookupProperty(depth0,"boolean"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"isInverted") : depths[1]),{"name":"boolean","hash":{},"data":data,"loc":{"start":{"line":78,"column":11},"end":{"line":78,"column":34}}}),"href":(depth0 != null ? lookupProperty(depth0,"linkUrl") : depth0),"class":"js-article"},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":2},"end":{"line":87,"column":9}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"f-content simple-article simple-article-summarized\" itemscope itemtype=\"http://schema.org/Article\"\n  data-cmsid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":20}}}) : helper)))
    + "\" data-cmsType=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":51}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"error") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":53,"column":9}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"c2a") : depth0)) != null ? lookupProperty(stack1,"0") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":0},"end":{"line":91,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-content-simple_article-simple_article_summarized'] = svs.content.simple_article.templates.simple_article_summarized;
})(svs, Handlebars);


 })(window);